'use client';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@components/ui/dropdown-menu-ak';
import { Label } from '@components/ui/label';
import { useTheme } from 'next-themes';

import { BiMoon, BiSun } from 'react-icons/bi';

interface ModeToggleProps {
  showLabel?: boolean;
  className?: string; // Add the className prop
}

export function ModeToggle({ showLabel = false, className }: ModeToggleProps) {
  const { setTheme, theme } = useTheme();

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger hideIcon className={`w-fit ${className} `}>
        <div className="flex items-center gap-3">
          {theme === 'light' ? <BiSun color="current" /> : <BiMoon color="current" />}
          {showLabel && (
            <div>
              <Label>Apperance</Label>
              <div className="capitalize">{theme}</div>
            </div>
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setTheme('light')}>Light</DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('dark')}>Dark</DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('system')}>System</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

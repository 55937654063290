import { ModeToggle } from '@components/ModeToggle/ModeToggle';
import { Avatar, AvatarImage, AvatarFallback } from '@components/ui/avatar';
import { useSession } from '@hooks/index'; // Import the useSession hook
import { Popover, PopoverContent, PopoverTrigger } from '@components/ui/popover';
import { Button } from '@components/ui/button';
import { IoLogOut } from 'react-icons/io5';
import { FiSettings } from 'react-icons/fi'; // Import settings icon
import Link from 'next/link'; // Import Link from Next.js

export default function SidebarProfileLink() {
  const { user, logOut } = useSession(); // Get the user object from useSession hook
  const userName = user?.name || 'Guest'; // Get the user's name or use 'Guest' as the default

  return (
    <div className="-mx-6 mt-auto hover:bg-muted/50">
      <div className="flex items-center justify-between w-full pr-6">
        <div className="w-full">
          <Popover>
            <PopoverTrigger>
              <div className="min-w-0 flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-primary">
                <Avatar>
                  <AvatarImage src={user?.image} alt={userName} />
                  <AvatarFallback>{userName.charAt(0)}</AvatarFallback>
                </Avatar>
                <span className="sr-only">Your profile</span>
                <span className="w-full truncate text-left" aria-hidden="true">
                  {userName}
                </span>
              </div>
            </PopoverTrigger>
            <PopoverContent className="">
              <div className="mx-4">
                <Link href="/settings" passHref>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="w-full !justify-start !text-left cursor-pointer gap-2 mb-2"
                  >
                    <FiSettings /> Settings
                  </Button>
                </Link>
                <Button
                  variant="ghost"
                  size="sm"
                  className="w-full !justify-start !text-left cursor-pointer gap-2"
                  onClick={() => logOut()}
                >
                  <IoLogOut /> Log Out
                </Button>
              </div>
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex-1">
          <ModeToggle className="hover:bg-background" />
        </div>
      </div>
    </div>
  );
}
